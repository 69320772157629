@import "styles/all";

:global {
  .Toastify__toast {
    border-radius: 8px;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
  }
  .dangerToast {
    background-color: $Danger100;
  }
  .warningToast {
    background-color: $Warning100;
  }
  .infoToast {
    background-color: $Primary100;
  }
  .successToast {
    background-color: $Success100;
  }
}

.container {
  display: flex;
  gap: 8px;
}
