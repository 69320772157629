@import "styles/all";

.fileDocumentStyle {
  cursor: auto !important;
  height: unset;
  padding: 6px !important;
  border-color: #d9d9d9 !important;

  &:hover {
    filter: unset;
  }
}
