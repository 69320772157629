@import "styles/all";

.container {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
}

.formSection,
.logoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formSection {
  width: 50%;

  padding-inline: desktop-responsive(80px);
  .formContainer {
    display: flex;
    flex-direction: column;
    width: desktop-responsive(615px);
    margin-inline: auto;
    gap: 2rem;
  }
}

.logoSection {
  align-items: center;
  width: 50%;
  padding: 24px;

  .imgElement {
    width: 100%;
    height: vh(976px) !important;

    background-repeat: no-repeat;
    background-position: -150px center;
    background-size: cover;
    border-radius: 60px;
  }
}
.image {
  width: 290;
  height: 90;
  margin-bottom: 16px;
}

@media (max-width: 480px) {
  .formContainer {
    align-items: center;
    width: 100% !important;
  }
  .formSection {
    align-items: center;
    width: 100%;
    padding: desktop-responsive(20px);
  }
  .logoSection {
    display: none;
  }
  .image {
    margin-bottom: 20px;
  }
  .imgElement {
    background-position: 0px center !important;
  }
}
