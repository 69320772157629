@import "variables";
@import "functions";

@mixin desktop {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin font($size, $weight) {
  font-size: $size !important;
  font-weight: $weight !important;
}

@mixin fontHeight($size, $weight) {
  font-size: $size !important;
  font-weight: $weight !important;
  line-height: $size !important;
}

@mixin mainHeader() {
  @include fontHeight(26px, 600);

  color: $TextPrimaryBlack;
  opacity: 0.98;
}
