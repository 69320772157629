@import "styles/all";

.formSection,
.logoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  font-family: "Montserrat-Regular";
}

.secondaryTitle {
  @include font(18px, 400);

  margin-top: desktop-responsive(24px);
  margin-bottom: desktop-responsive(56px);
  color: $Primary800;
}

.form {
  .submitBtn {
    justify-content: center;
    width: 100%;
    height: desktop-responsive(56px);
    margin-top: desktop-responsive(20px);
  }
}

.forgotPassword {
  @include font(16px, 500);

  margin-top: desktop-responsive(32px);
  color: $Neutral900;
  text-align: center;
}

@media (max-width: 480px) {
  .form {
    width: 270px;
    margin-top: 20px;
    .submitBtn {
      height: 52px;
    }
  }
}
