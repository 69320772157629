@import "styles/all";

:global {
  .ant-modal-content {
    padding: 0px !important;
    border-radius: 12px !important;
    .ant-modal-header {
      margin-bottom: 0;
      padding-top: 24px;
      padding-bottom: 24px;
      padding-inline: 40px;
    }
    .ant-modal-close {
      top: 26px;
      inset-inline-end: 40px;
      width: 32px;
      height: 32px;
    }
  }
  .ant-modal .ant-modal-title {
    padding-left: 20px;
    font-size: 24px;
    font-weight: 400;
  }
}

.modalContainer {
}

.containerStyle {
}
