@import "styles/all";

.tableContainer {
  margin-bottom: 24px;
  background-color: #fff;
  border: $TableBorder;
  border-radius: 24px;

  .inputCheckbox {
    cursor: pointer;
    transform: scale(1.5);
  }

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 32px;

    border-bottom: 1px solid #eee;

    .btnContainer {
      display: flex;
      column-gap: 24px;
    }

    span {
      @include mainHeader();
    }

    @media (max-width: 460px) {
      flex-direction: column;
      row-gap: 20px;
    }
  }

  .formContainer {
    max-width: 520px;
    padding-top: 24px;
    padding-bottom: 44px;
    padding-inline: 32px;

    .labelText {
      @include fontHeight(16px, 400);

      display: inline-block;
      margin-bottom: 12px;
    }

    .datePickerStyle {
      width: 100%;
      padding: 18px 24px;

      font-size: 24px;

      border: 1px solid #c9c9c9;
      border-radius: 12px;

      input {
        @include fontHeight(16px, 600);

        color: #000;
      }
    }

    .countryCodeContainer {
      display: flex;
      column-gap: 14px;
      align-items: center;
      justify-content: center;

      padding: 8px 16px;

      border: $TableBorder;
      border-radius: 12px;

      .selectInput {
        width: 92px;
        background-color: #a292611a;
        border-radius: 12px;

        & > div {
          background-color: unset;
          border: unset;
        }
      }
      .selectInputfilter {
        width: 150px !important;
        background-color: #a292611a;
        border-radius: 12px;

        & > div {
          background-color: unset;
          border: unset;
        }
      }

      .inputField {
        flex: 1;
        input {
          height: unset !important;
          padding: unset !important;
          border: unset !important;
          border-radius: unset !important;

          &:focus {
            box-shadow: unset;
          }
        }
      }
    }
  }
}

.accommodationTableContainer {
  :global {
    .ant-table-thead {
      tr {
        th:nth-last-child(2) {
          text-align: center;
          border-right: 1px solid #eeeeee;
        }
      }
    }
    td:nth-last-child(2) {
      border-right: 1px solid #eeeeee;
    }
    .antdTableOddRowBg {
      td:nth-last-child(2) {
        background-color: #f9f9f9;
      }
    }
  }

  .statusFlag {
    display: inline-block;

    padding-block: 8px;
    padding-inline: 46px;

    line-height: 22px;

    border-radius: 12px;
  }

  .booked {
    color: rgb(26, 153, 70);
    background-color: rgba(26, 153, 70, 0.14);
  }

  .waiting {
    color: rgb(201, 120, 23);
    background-color: rgba(201, 120, 23, 0.14);
  }
}

/*  Add New Arrival */
.addNewArrivalContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
  padding-block: 26px;
  padding-inline: 40px;

  background-color: #eee;
  border: 1px solid #eee;
  border-radius: 16px;

  .buttonContainer {
    display: flex;
    column-gap: 24px;

    button {
      justify-content: center;
      width: 210px;
    }

    @media (max-width: 460px) {
      flex-direction: column;
      row-gap: 20px;
    }
  }

  @media (max-width: 540px) {
    flex-direction: column;
    row-gap: 20px;
  }
}

.headerTitle {
  @include mainHeader();
}

.btnStyle {
  @include fontHeight(16px, 600);

  padding: 18px 45px !important;
}

.tableHeader {
  display: flex;
  border-bottom: 1px solid #eee;

  .dateColumn {
    @include fontHeight(16px, 600);

    padding-block: 20px;
    padding-inline: 32px 82px;
    color: $TextPrimaryBlack;
    border-right: 1px solid #eee;

    @media (max-width: 480px) {
      padding-inline: 16px 0px;
    }
  }

  .pathwayColumn {
    @include fontHeight(16px, 600);

    flex: 1;
    padding-left: 68px;
    padding-block: 20px;
    color: $TextPrimaryBlack;

    @media (max-width: 480px) {
      padding-left: 28px;
    }
  }
}
