@import "styles/all";

.tableWrapper {
  width: 100%;
  margin-top: 30px;

  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 16px;

  .tableHeader {
    display: flex;
    column-gap: 18px;
    align-items: center;
    justify-content: space-between;

    padding-block: 26px;
    padding-inline: 33px;

    border-bottom: 1px solid #eeeeee;

    .titleContainer {
      flex: 1;

      h2 {
        @include font(26px, 600);

        color: $TextPrimaryBlack;
        opacity: 0.98;
      }

      span {
        @include fontHeight(16px, 500);

        color: $TextPrimaryBlack;
        opacity: 0.64;
      }
    }

    button {
      @include fontHeight(16px, 600);

      height: 52px;
      padding-block: 18px;
      padding-inline: 33px;

      color: #fff;

      background-color: $Primary700;
      border-radius: 16px;
    }

    @media (max-width: 540px) {
      flex-direction: column;
      row-gap: 20px;
    }
  }

  .tableActions {
    display: flex;
    column-gap: 32px;
    justify-content: space-between;

    margin-bottom: 20px;
    padding-block: 24px;
    padding-inline: 32px;

    border-bottom: 1px solid #eeeeee;

    .tableInput {
      flex: 1;
    }

    .filterContainer {
      padding-block: 16px;
      padding-inline: 32px;
      border: 1px solid #d6d6d6;
      border-radius: 16px;

      span {
        margin-left: 16px;
        color: $TextPrimaryBlack48;
      }
    }

    @media (max-width: 540px) {
      flex-direction: column;
      row-gap: 20px;
    }
  }
}

.DropdownBtn{
  background-color:transparent;
  border: none !important;
}
