@import "styles/all";

:global {
  .ant-picker-cell-inner {
    border: 0px solid;
    border-radius: 50% !important;
  }
}

.container {
  width: 100%;
}

.bodySection {
  display: flex;
  padding-inline: desktop-responsive(46px);
  border-top: 1px solid $BorderGray;
}

.leftBtnsContainer {
  min-width: desktop-responsive(204px);
  padding-block: desktop-responsive(34px);
  padding-inline-end: desktop-responsive(28px);
  border-inline-end: 1px solid $BorderGray;
}

.leftTitle {
  @include font(14px, 400);

  color: $Neutral700;
}

.leftBtns {
  margin-block: 24px;
  :last-child {
    margin-block-start: 24px;
  }
}

.customBtn {
  padding-left: 0 !important;
  border: none;
}

.datePickersContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  * {
    font-weight: bold;
  }
}

.popup {
  position: unset;
  box-shadow: none;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: end;

  padding: desktop-responsive(24px) desktop-responsive(40px);

  border-top: 1px solid $BorderGray;

  .confirmBtnStyle {
    height: unset;
    padding: 16px 56px !important;
    font-size: 16px !important;
    line-height: 16px;
  }
}

.count {
  .first {
    @include font(14px, 400);

    color: $Neutral700;
  }
  .second {
    @include font(14px, 700);

    margin-inline-end: desktop-responsive(32px);
  }
}
