@import "styles/all";

.container {
  .inputContent {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  > span {
    @include font(16px, 400);

    display: block;
    margin-bottom: 12px;
    color: $Neutral900;
    text-transform: capitalize;
  }

  .input {
    :global {
      @include font(16px, 600);

      height: 56px !important;
      padding: 17px 24px !important;

      font-weight: 600;
      color: #121212;

      background: $Neutral0;
      border-radius: 12px !important;
    }
  }
}
