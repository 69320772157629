@import "styles/all";

.container {
  padding-top: 10px;
  padding-bottom: 40px;

  :global {
    .ant-picker-range {
      height: 50px !important;
    }
  }
}
.vehicleContainer {
  h1 {
    margin: 0;
    padding: 0;
  }
  p {
    display: none;
  }
}
