@import "styles/all";

.mainHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  margin-bottom: 40px;

  .notificationContainer {
    display: flex;
    column-gap: 22px;
    .notificationBtn {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 50px;
      height: 50px;

      border-radius: 100%;
    }
  }
}

.profileStyle {
  display: flex;
  column-gap: 6px;
  align-items: center;

  padding-block: 8px;
  padding-inline: 16px;

  border-radius: 24px;
}

@media (max-width: 768px) {
  .mainHeader {
    flex-wrap: wrap;
    justify-content: center;
  }
}
