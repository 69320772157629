@import "styles/all";

.modalContainer {
  width: unset !important;
  max-width: calc(100vw - 127px);

  :global {
    .ant-modal-content {
      border: 1px solid #eee;
      border-radius: 16px !important;
    }
  }
}

.headerContainer {
  padding-block: 27px 24px;
  padding-inline: 30px;
  border-bottom: 1px solid #eee;

  & > span {
    @include fontHeight(26px, 500);

    color: $TextPrimaryBlack;
  }
}
