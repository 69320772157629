$desktop-design-viewport: 1440px;
$mobile-design-viewport: 375px;
$desktop-design-height: 1024px;
$navbar-height: 104px;
$sidebar-width: 248px;

/* Colors */
$Neutral0: #ffffff;
$Neutral100: #f8f9fc;
$Neutral200: #f1f3f9;
$Neutral300: #e1e6ef;
$Neutral700: #3f444d;
$Neutral800: #23272f;
$Neutral900: #1b1f27;
$Neutral1000: #0a0d14;
$Primary100: #a2926129;
$Primary700: #a29261;
$Primary800: #cbb670;
$Primary900: #705e25;
$Success100: #edfdf8;
$Success700: #daece0;
$Success800: #1a9946;
$Success900: #066042;
$Warning100: #fff8eb;
$Warning700: #b25e09;
$Warning800: #96530f;
$Warning900: #80460d;
$Danger100: #fef1f2;
$Danger700: #d73d38;
$Danger800: #ba2532;
$Danger900: #981b25;

$TextPrimaryBlack: #222425;
$TextPrimaryBlack48: #2224257a;
$TextSecondaryBlack: #1d2433cc;
$TextDisabledBlack: #1d2433a6;
$TextPrimaryWhite: #ffffff;
$TextSecondaryWhite: #ffffffbf;
$TextDisabledWhite: #ffffff99;

$IconGray: #bababa;
$BackgroundGray: #fafafa;
$BorderGray: #c9c9c9;
$MainDanger: #d92d20;

/* CUSTOM */
$MainBorder: 1px solid #f5f5f5;
$TableBorder: 1px solid #eeeeee;
