@import "styles/all";

.rowContainer {
  display: flex;
  border-bottom: 1px solid #eee;

  .dateCell {
    @include fontHeight(16px, 500);

    padding-left: 32px;
    padding-block: 24px;

    color: $TextPrimaryBlack;

    opacity: 0.8;
    border-right: 1px solid #eee;

    @media (max-width: 480px) {
      padding-left: 16px;
    }
  }

  .row {
    flex: 1;
    padding-left: 24px;
    padding-block: 24px;

    & > div {
      margin-bottom: 16px;
    }

    @media (max-width: 480px) {
      padding-left: 14px;
    }
  }

  .tripRow {
    :global {
      .clickable-cell {
        cursor: pointer;
        text-decoration: underline;
      }
      .ant-spin-container {
        .ant-table-content,
        .ant-table-body {
          &::-webkit-scrollbar {
            width: 0px;
          }
        }

        th.ant-table-cell {
          padding: 12px 0 0 0 !important;
        }
        td.ant-table-cell {
          padding: 0 12px 0 !important;
        }

        .ant-table-content {
          table {
            colgroup {
              col {
                &:first-child,
                &:last-child {
                  @media (max-width: 540px) {
                    width: 50px !important;
                  }
                }

                @media (max-width: 540px) {
                  width: 100px !important;
                }
              }
            }

            td,
            th {
              background-color: #a292611a !important;
              border-bottom: none !important;
            }

            thead {
              tr:last-child {
                td:first-child {
                  border-left: 8px solid #a29261;
                  border-top-left-radius: 6px;
                  .ant-checkbox .ant-checkbox-inner {
                    width: 24px;
                    height: 24px;

                    &:after {
                      width: 6px;
                      height: 11px;
                    }
                  }
                }
              }
            }

            tbody {
              tr:not(:first-child) {
                td:first-child {
                  border-left: 8px solid #a29261;
                }

                td:first-child,
                td:last-child {
                  padding-top: 0px !important;
                  padding-bottom: 36px !important;

                  @media (max-width: 540px) {
                    min-height: 90px;
                  }
                }
              }

              tr:last-child {
                td:first-child {
                  border-bottom-left-radius: 6px;

                  span.ant-checkbox-inner {
                    width: 24px;
                    height: 24px;

                    &:after {
                      top: 36%;
                      width: 6px;
                      height: 15px;
                    }
                  }
                }
              }
            }
          }
        }

        & > ul {
          display: none !important;
        }
      }
    }
  }

  .flightRow {
    :global {
      .clickable-cell {
        cursor: pointer;
        text-decoration: underline;
      }
      .ant-spin-container {
        .ant-table-content,
        .ant-table-body {
          &::-webkit-scrollbar {
            width: 0px;
          }
        }

        th.ant-table-cell {
          padding: 12px 0 0 0 !important;
        }
        td.ant-table-cell {
          padding: 0 12px 0 !important;
        }

        .ant-table-content {
          table {
            colgroup {
              col {
                &:first-child,
                &:last-child {
                  @media (max-width: 540px) {
                    width: 50px !important;
                  }
                }

                @media (max-width: 540px) {
                  width: 100px !important;
                }
              }
            }

            td,
            th {
              background-color: #e3eaf6 !important;
              border-bottom: none !important;
            }

            thead {
              tr:last-child {
                td:first-child {
                  border-left: 8px solid #4472c4;
                  border-top-left-radius: 6px;
                  .ant-checkbox .ant-checkbox-inner {
                    width: 24px;
                    height: 24px;

                    &:after {
                      width: 6px;
                      height: 11px;
                    }
                  }
                }
              }
            }

            tbody {
              tr:not(:first-child) {
                td:first-child {
                  border-left: 8px solid #4472c4;
                }

                td:first-child,
                td:last-child {
                  padding-top: 0px !important;
                  padding-bottom: 36px !important;

                  @media (max-width: 540px) {
                    min-height: 90px;
                  }
                }
              }

              tr:last-child {
                td:first-child {
                  border-bottom-left-radius: 6px;

                  span.ant-checkbox-inner {
                    width: 24px;
                    height: 24px;

                    &:after {
                      top: 36%;
                      width: 6px;
                      height: 15px;
                    }
                  }
                }
              }
            }
          }
        }

        & > ul {
          display: none !important;
        }
      }
    }
  }

  .flightAndTripRow {
    :global {
      .clickable-cell {
        cursor: pointer;
        text-decoration: underline;
      }
      .ant-spin-container {
        .ant-table-content,
        .ant-table-body {
          &::-webkit-scrollbar {
            width: 0px;
          }
        }

        th.ant-table-cell {
          padding: 12px 0 0 0 !important;
        }
        td.ant-table-cell {
          padding: 0 12px 0 !important;
        }

        .ant-table-content {
          table {
            colgroup {
              col {
                &:first-child,
                &:last-child {
                  @media (max-width: 540px) {
                    width: 50px !important;
                  }
                }

                @media (max-width: 540px) {
                  width: 100px !important;
                }
              }
            }

            td,
            th {
              background-color: #e7e7e7 !important;
              border-bottom: none !important;
            }

            thead {
              tr:last-child {
                td:first-child {
                  border-left: 8px solid #6969698a;
                  border-top-left-radius: 6px;
                  .ant-checkbox .ant-checkbox-inner {
                    width: 24px;
                    height: 24px;

                    &:after {
                      width: 6px;
                      height: 11px;
                    }
                  }
                }
              }
            }

            tbody {
              tr:not(:first-child) {
                td:first-child {
                  border-left: 8px solid #6969698a;
                }

                td:first-child,
                td:last-child {
                  padding-top: 0px !important;
                  padding-bottom: 36px !important;

                  @media (max-width: 540px) {
                    min-height: 90px;
                  }
                }
              }

              tr:last-child {
                td:first-child {
                  border-bottom-left-radius: 6px;

                  span.ant-checkbox-inner {
                    width: 24px;
                    height: 24px;

                    &:after {
                      top: 36%;
                      width: 6px;
                      height: 15px;
                    }
                  }
                }
              }
            }
          }
        }

        & > ul {
          display: none !important;
        }
      }
    }
  }
}
