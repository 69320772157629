@import "styles/all";

.tableWrapper {
  width: 100%;
  margin-top: 30px;

  background-color: #fff;
  border-radius: 18px;
}
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  padding: 32px;
}
.addNewFile {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  width: 160px;
  height: 160px;
  margin-right: 16px;
  margin-bottom: 10px;
  padding: 5px;

  background-color: $BackgroundGray;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  text-align: center;
  p {
    margin-bottom: 0;
    text-align: center;
  }
}
.btnTitle {
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
  max-width: 146px;
}
.deleteBtn {
  background-color: transparent !important;
  color: black !important;
  border-color: transparent !important;
  margin-left: 70% !important;
  font-weight: bold !important;
}
