@import "styles/all";

.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color:#22242580;
  border: none;
}

.modalBox {
  background-color: $Neutral0;
  border-radius: 16px;
}

.small {
  width: desktop-responsive(775px);
}

.large {
  width: desktop-responsive(1190px);
}

.modalContainer {
  display: flex;
  padding: desktop-responsive(24px) desktop-responsive(40px);
}

.body {
  padding: 0;
}

.start {
  justify-content: start;
}

.center {
  justify-content: center;
}

.end {
  justify-content: end;
}

.header {
  border-bottom: 1px solid $Neutral200;
}

.headerTitle {
  display: flex;
  align-items: center;
  width: 100%;
}

.footer {
  justify-content: end;
  border-top: 1px solid $Neutral200;
}
