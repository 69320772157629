@import "styles/all";
@import "~bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  font-family: "SF-Pro-Text", sans-serif !important;
  background-color: $BackgroundGray;
}

* {
  font-family: "SF-Pro-Text", sans-serif !important;
}

.ant-select-dropdown {
  z-index: 99999999999999 !important;
}

.ant-form-item-explain {
  margin-bottom: 24px;
}

.ant-picker-range-arrow {
  display: none;
}

.ant-picker-dropdown .ant-picker-panel-container {
  box-shadow: none;
  transition: none;
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner,
.ant-picker-dropdown td {
  min-width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}

.ant-picker-dropdown .ant-picker-cell::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range-hover::before {
  height: 32px !important;
}

// font Sizes Config

.Headline400 {
  font-family: "Montserrat-Regular" !important;
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
}

.Headline500 {
  font-family: "Montserrat-Regular" !important;
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
}

.Headline600 {
  font-family: "Montserrat-Regular" !important;
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
}

.Paragraph400Heavy {
  font-family: "Montserrat-Regular" !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

.Paragraph400Light {
  font-family: "Montserrat-Regular" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

.Paragraph500Light {
  font-family: "Montserrat-Regular" !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.Paragraph600Light {
  font-family: "Montserrat-Regular" !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

.Button100 {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  letter-spacing: 0.32px;
}

.Button200 {
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-transform: capitalize;
  letter-spacing: 0.24px;
}

.Label100 {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  letter-spacing: 0.32px;
}

.Label200 {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  letter-spacing: 0.28px;
}

.Label300 {
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-transform: capitalize;
  letter-spacing: 0.24px;
}

.Label400 {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-transform: capitalize;
  letter-spacing: 0.2px;
}

/* Antd Table */

.ant-table-container {
  .ant-table-content {
    overflow: auto !important;
  }

  .ant-table-content,
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #eeeeee;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bdbdbd;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #b9b9b9;
    }
  }
}

table {
  thead {
    tr {
      th {
        // background-color: red !important;
        padding: 24px 0px !important;
        text-align: left !important;
        width: 10px !important;

        &::before {
          content: unset !important;
        }
      }
      td {
        &::before {
          content: unset !important;
        }
      }
    }

    tr:nth-child(2) {
      th {
        font-weight: 500 !important;
      }
    }
  }
  td {
    color: rgba(34, 36, 37, 0.8);

    &:nth-last-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.antdTableOddRowBg {
  background-color: #f9f9f9;

  td:nth-child(1),
  td:nth-last-child(1) {
    background-color: #f9f9f9;
  }
}

.antdTableRowZIndex {
  position: relative;
  z-index: 999999;
}

.antdTableNewArrival {
  background-color: #f0ede6;
}

.leftColumn {
  text-align: left !important;
  justify-content: left !important;
}

@media screen and (min-width: 600px) {
  @media screen and (max-height: 722px) {
    body:has(.newArrival) {
      .ant-picker-dropdown {
        top: 418px !important;
      }
    }
  }
}
