@import "styles/all";

.infoCardContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 35px;
  justify-content: space-between;

  button {
    flex: 1;
    min-width: 230px;
  }
}

.infoCard {
  width: 264px;
  margin-bottom: 10px;
  padding-block: 23px;
  padding-inline: 20px;

  border-radius: 16px;

  .innerInfoCard {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .textSide {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        @include fontHeight(18px, 500);

        opacity: 0.64;
      }
      span {
        @include fontHeight(24px, 600);

        color: #010101;
      }
    }

    .iconContainer {
      padding: 10px;
      background-color: $Primary100;
      border-radius: 12px;
    }
  }
}

/*  Add New Arrival */
.addNewArrivalContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
  padding-block: 26px;
  padding-inline: 40px;

  background-color: #eee;
  border: 1px solid #eee;
  border-radius: 16px;

  .buttonContainer {
    display: flex;
    column-gap: 24px;

    button {
      justify-content: center;
      width: 210px;
    }

    @media (max-width: 460px) {
      flex-direction: column;
      row-gap: 20px;
    }
  }

  @media (max-width: 540px) {
    flex-direction: column;
    row-gap: 20px;
  }
}

.headerTitle {
  @include mainHeader();
}

/* RESERVATION */

.reservationContainer {
  display: flex;
  column-gap: 24px;
  align-items: stretch;
  justify-content: space-between;

  .textSide {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;

    padding: 26px 40px;

    background-color: #f8f7f3;
    border: 1px solid #eee;
    border-radius: 24px;

    & > div {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 100px;
    }

    .title {
      @include fontHeight(18px, 600);

      margin-bottom: 16px;
      color: $TextPrimaryBlack;

      & ~ span {
        @include fontHeight(18px, 500);

        color: $TextPrimaryBlack;
        opacity: 0.8;
      }
    }
  }

  .priceSide {
    display: flex;
    column-gap: 12px;
    align-items: center;

    padding: 24px 40px;

    background-color: #f8f7f3;
    border: 1px solid #eee;
    border-radius: 14px;

    .imgContainer {
      padding: 9px;
      background-color: white;
      border: 1px solid rgb(217, 217, 217);
      border-radius: 14px;
    }
  }

  @media (max-width: 770px) {
    flex-direction: column;
    row-gap: 20px;
  }
}
