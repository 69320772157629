@import "styles/all";

.btnStyle {
  @include fontHeight(16px, 600);

  height: 52px;
  padding-block: 18px;
  padding-inline: 33px;

  color: #fff;

  background-color: $Primary700;
  border-radius: 16px;
  border: none;
}

.tableHeader {
  display: flex;
  border-bottom: 1px solid #eee;

  .dateColumn {
    @include fontHeight(16px, 600);

    padding-block: 20px;
    padding-inline: 32px 82px;
    color: $TextPrimaryBlack;
    border-right: 1px solid #eee;

    @media (max-width: 480px) {
      padding-inline: 16px 0px;
    }
  }

  .pathwayColumn {
    @include fontHeight(16px, 600);

    flex: 1;
    padding-left: 68px;
    padding-block: 20px;
    color: $TextPrimaryBlack;

    @media (max-width: 480px) {
      padding-left: 28px;
    }
  }
}

:global {
  .ant-modal-title {
    font-size: 20px !important;
    font-weight: 600 !important;
  }

  .ant-space-item {
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .ant-select {
    @media (max-width: 500px) {
      width: 100% !important;
    }
  }
}

.modalDriver {
  font-size: 26px;

  .container {
    padding-inline: 60px;
  }

  .description {
    font-size: 20px;
  }

  .selectDriver {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;

    .title {
      font-size: 16px;
    }
  }

  .btnHolder {
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .lineBreak {
      border-top: 1px solid #ececec;
      position: absolute;

      width: 100% !important;
    }

    .btnConfirm {
      width: 100%;
      background-color: #a29261;
      outline: none;
      border: none;
      padding: 1rem;
      border-radius: 12px;
      color: white;
      font-weight: 600;
      letter-spacing: 0.5px;
      font-size: 15px;
      transition: all 0.3s;

      &:hover {
        background-color: #877b58;
      }
    }
    .btnCancel {
      width: 100%;
      background-color: #ffffff;
      outline: none;
      border: solid 1px #d3d4d4;
      padding: 1rem;
      border-radius: 12px;
      color: #919192;
      font-weight: 600;
      letter-spacing: 0.5px;
      font-size: 15px;
      transition: all 0.3s;

      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
}
