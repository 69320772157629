@import "styles/all";


.Maincontainer{
    overflow-x: hidden;
    overflow-y: auto;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 50vh;

    text-align: center;
    text-align: justify;
.container {
  display: flex;
  gap: 20px;
  align-items: center;

  width: 70%;
  height: 48px;
  margin-bottom: 20px;
  padding-inline: 30px;

  background-color: $Primary800;
  border-radius: 15px;
}
}