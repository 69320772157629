@import "styles/all";

.container {

    @include desktop {
        background-color: blue;
    }

    @include mobile {
        background-color: red;
    }

    font-size: desktop-responsive(21px);
}
