@import "styles/all";
.booked {
  color: rgb(26, 153, 70);
  background-color: rgba(26, 153, 70, 0.14);
  display: inline-block;
  padding-block: 8px;
  padding-inline: 46px;
  line-height: 22px;
  border-radius: 12px;
}

.waiting {
  color: rgb(201, 120, 23);
  background-color: rgba(201, 120, 23, 0.14);
  display: inline-block;
  padding-block: 8px;
  padding-inline: 46px;
  line-height: 22px;
  border-radius: 12px;
}
.cursorPointer {
  cursor: pointer;
}
