@import "styles/all";

.formSection,
.logoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  @include font(40px, 500);

  color: #000;
}

.secondaryTitle {
  @include font(16px, 400);

  margin-top: desktop-responsive(24px);
  margin-bottom: desktop-responsive(56px);
  color: $Neutral700;
}

.form {
  .dontReceive {
    @include font(16, 400);
  }
  .submitBtn {
    justify-content: center;
    width: 100%;
    height: desktop-responsive(56px);
    margin-top: desktop-responsive(80px);
  }
}

@media (max-width: 480px) {
  .form {
    margin-top: 20px;
    .submitBtn {
      height: 52px;
    }
  }
  .secondaryTitle {
    text-align: center;
  }
}
