@import "styles/all";

.container {
}

.mainTitle {
  @include font(40px, 400);
}

.secondTitle {
  @include font(18px, 400);

  opacity: 0.64;
}
