@import "styles/all";

.actionBtn {
  height: unset !important;
  padding: 0px !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

:global {
  .ant-table-cell:last-child {
    display: table-cell !important;
  }
}

.mainBtn {
  justify-content: center;
  align-items: center;
  display: flex;
}

.actionEditBtn {
  position: relative;

  height: unset;
  padding: 7px !important;

  border-color: #cecece !important;
  border-radius: 10px !important;
}

.floatingMenu {
  position: absolute;
  z-index: 999;
  top: -35px;
  left: -164px;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 160px;
  margin-bottom: 0;
  padding: 0;

  background-color: white;
  border: 1px solid #eee;
  border-radius: 12px;

  button {
    padding-block: 12px;
    padding-inline: 10px 16px;

    text-align: left;

    background-color: transparent;
    border: none;
    border-bottom: 1px solid #eee;

    &:active,
    &:hover {
      background-color: rgb(162 146 97 / 12%);
    }
  }
}
