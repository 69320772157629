@import "styles/all";

.sidebarButtonContainer {
  display: flex;
  gap: 12px;
}

//
.sidenav {
  position: fixed;
  z-index: 1;
  top: 0;
  inset-inline-start: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50px;
  height: 100%;
  padding-top: 33px;

  background-color: #fff;
  border-inline-end: solid 1px #cecece;

  transition: all 300ms ease-in-out;

  &.open {
    /*width: desktop-responsive($sidebar-width);*/
    width: 248px;
  }

  a {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 4px;

    padding-left: 20px;
    padding-block: 18px;

    color: $Neutral700;
    text-decoration: none;

    transition: all 300ms ease-in-out;

    &:hover,
    &.active {
      background-color: $Primary100;
      border-radius: 16px;

      span {
        color: $Primary700;
      }

      svg {
        fill: $Primary800 !important;
      }
    }

    span {
      @include font(16px, 600);

      color: $IconGray;
      text-align: center;
    }
  }

  .toggle-btn {
    position: absolute;
    z-index: 20;
    right: 0;
    bottom: 4%;
    transform: translateX(50%) rotate(0);

    padding: 5px;

    background-color: $Neutral0;
    border: none;
    border-radius: 50%;

    transition: all 300ms ease-in-out;

    &.open {
      transform: translateX(50%) rotate(180deg);
    }
  }

  .navigationContent {
    padding-inline: desktop-responsive(16px);
  }
}

.sidelayoutContent {
  height: 100%;
  padding-top: 33px;
  padding-right: 40px;
  padding-inline-start: 290px;

  transition: padding-inline-start 300ms ease-in-out;
}

.toggleButton {
  width: 100%;
  margin-top: 10px;
  /* margin-left: 10px; */
  padding: 5px;
}

.sideBarButton {
  padding: 8px;
  border: none;
  border-radius: 6px;
}

.CancelSideBarButton {
  position: absolute;
  top: 10px;
  right: 5px;

  background: none;
  border: none;
}

.sidenav.visible {
  z-index: 1000;
  width: 250px;
  height: 100vh;
}

@media (max-width: 877px) {
  .sidenav {
    overflow-x: hidden;
    width: 0;
    transition: width 0.3s ease;
  }

  .sidenav.open {
    width: 0px;
  }

  .sidenav.visible {
    width: 250px;
  }

  .sidelayoutContent {
    padding: 1.25rem;
    /* padding-inline-start: 0px;*/
    transition: padding-inline-start 300ms ease-in-out;

    @media (max-width: 540px) {
      padding-inline: 0.25rem;
    }
  }
}

@media (min-width: 768px) {
  .toggleButton {
    display: none;
  }
  .CancelSideBarButton {
    display: none;
  }
}
